.hero {
  height: 940px;
  background-color: #ebeae8;
  position: relative;
  transition: $trans;
  @media screen and (max-width: 991px) {
    height: 680px;
    transition: $trans;
  }

  @media screen and (max-width: 575px) {
    height: 20rem;
    transition: $trans;
  }

  &__overlay {
    position: absolute;
    background: rgba($color: #000000, $alpha: 0.6);
    top: 0;
    bottom: 0;
    width: 100%;
    z-index: 2;
    transition: $trans;

    @media screen and (max-width: 767px) {
      background: rgba($color: #000000, $alpha: 0.6);
      //display: none;
      transition: $trans;
    }
  }

  &__bg {
    top: 0;
    bottom: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    position: absolute;
    z-index: 1;
    transition: $trans;

    @media screen and (min-width: 1419px) {
      // max-width: 1419px;
      margin-left: auto;
      margin-right: auto;
      transition: $trans;
    }
    @media screen and (min-width: 576px) {
      display: none;
    }
  }

  &__video {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    object-fit: cover;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
    transition: $trans;

    @media screen and (min-width: 1419px) {
      // max-width: 1419px;
      margin-left: auto;
      margin-right: auto;
      transition: $trans;
    }

    @media screen and (max-width: 575px) {
      display: none;
    }
  }

  &__container {
    position: relative;
    z-index: 2;
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: flex-start;
    transition: $trans;
    @media screen and (max-width: 575px) {
      align-items: flex-end;
      transition: $trans;
    }
  }
  &__content {
    background-color: $color-white;
    width: max-content;
    padding: $space-1;
    border-radius: $border-radius;
    transition: $trans;
    max-width: 410px;
    @media screen and (max-width: 991px) {
      padding: $space-1;
      transition: $trans;
    }

    // @media screen and (min-width: 768px) {
    //   width: 75%;
    //   transition: $trans;
    // }
    // @media screen and (min-width: 991px) {
    //   width: 65%;
    //   transition: $trans;
    // }
    // @media screen and (max-width: 767px) {
    //   width: 100%;
    //   transition: $trans;
    // }
    @media screen and (max-width: 575px) {
      width: 100%;
      transform: translateY(20rem);
      background-color: transparent;
      padding: 0;
      transition: $trans;
    }
    h2 {
    }
    h3 {
    }
  }
  &__titulo {
    color: $color-black;
    font-weight: bold;
    font-size: $header-3;
    margin-bottom: 0;
    transition: $trans;

    // @media screen and (max-width: 1199px) {
    //   font-size: $header-1;
    //   transition: $trans;
    // }
    // @media screen and (max-width: 991px) {
    //   font-size: $header-1-res;
    //   transition: $trans;
    // }
    // @media screen and (max-width: 575px) {
    //   font-size: $header-2;
    //   transition: $trans;
    // }
  }
  &__subtitulo {
    color: $color-black;
    border-radius: 3px;
    font-weight: 500;
    margin-bottom: 1rem;
    font-size: $font-size-bigger;
    transition: $trans;

    @media screen and (max-width: 991px) {
      margin-bottom: 1rem;
      // font-size: $header-3-res;
      transition: $trans;
    }
  }

  &__titulo,
  &__subtitulo {
    @media screen and (max-width: 575px) {
      text-align: center;
      color: $color-white;
    }
  }

  &__motor {
    @media screen and (max-width: 575px) {
      margin-top: $space-1;
    }
  }
}
