/* Article Post — Page - Old
--------------------------------------------------------------------------- */
article.post {
  margin-bottom: 80px;
}
article.post .entry p {
  color: #787878;
}
article.post .entry p strong {
  color: $color-secondary;
}
article.post .entry h2 {
}
article.post .entry h3 {
}
article.post .entry h4 {
  font-size: 1.1rem;
  text-transform: uppercase;
  color: $color-primary;
}

article.post > h2 {
  font-size: 3rem;
}
article.post > time {
  margin-bottom: 30px;
  display: block;
}
article.post .post__img {
  width: 100%;
  height: 350px;
  background-size: cover;
  margin-bottom: 15px;
}

article.post .entry {
}
article.post .entry img.size-full,
article.post .entry img.size-large {
  width: 100%;
  height: auto;
  margin: 20px 0;
}

article.post .entry .size-full,
article.post .entry .size-large,
article.post .entry .size-medium,
article.post .entry .size-thumbnail {
  padding: 5px;
  border-radius: 3px;
  border: 1px solid #d6d6d6;
  background-color: #fff;
}

.single article.post .entry {
  margin-bottom: 30px;
  padding-bottom: 30px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}
