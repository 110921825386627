/* Navegación - Old
--------------------------------------------------------------------------- */

ul#menu-principal {
  padding-left: 30px;
  li a.nav-link {
    font-weight: 400;
    font-weight: bold;
  }
}

.top-bar {
  &__container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: $space-1;
  }
  &__burger {
    margin-right: $space-1;
    justify-self: flex-start;
    .bi {
      font-size: 2.5rem;
      color: $color-text-body;
      &:hover {
        color: $color-primary;
      }
    }
    @media screen and (min-width: 1231px) {
      display: none;
    }
  }
  &__logo img {
    width: 250px;
  }
  &__nav {
    @media screen and (max-width: 1230px) {
      display: none;
    }
  }
  &__cart {
    margin-left: auto;
  }
}

.carrito {
  display: flex;
  justify-content: flex-end;

  &__items {
    font-size: 0.8rem;
    text-align: right;
    padding-right: 10px;
    @media screen and (max-width: 991px) {
      display: none;
    }
  }
  &__ico {
    width: 35px;
    height: auto;
  }
}

.nav-primary {
  &__items {
    list-style: none;
    padding-left: 0;
    margin-bottom: 0;
    display: flex;
    align-items: center;
    & > li.menu-item {
      a {
        color: $color-text-body;
        font-size: $font-size;
        font-family: $typo-primary;
        text-decoration: none;
        padding: $font-size-smaller $font-size-small;
        @media screen and (max-width: 1400px) {
          padding-left: 14px;
          padding-right: 14px;
        }
        &:hover {
          color: $color-primary;
        }
        @media screen and (max-width: 1320px) {
          font-size: 0.95rem;
          padding-left: $font-size-smaller;
          padding-right: $font-size-smaller;
        }
      }
    }
    li.menu-item-has-children {
      position: relative;
      padding-right: 0.5rem;
      .sub-menu {
        background-color: $color-background;
        list-style: none;
        padding-left: 0;
        margin-bottom: 0;
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
        display: none;
        position: absolute;
        box-shadow: $box-shadow;
        top: 2rem;
        z-index: 2;
        border-radius: 5px;
        li {
          min-width: max-content;
          a {
            color: $color-text-body;
            font-weight: $font-weight-medium;

            padding: 0.5rem 1.25rem;
            font-weight: normal;
            display: block;
            text-decoration: none;
            &:hover {
              background-color: $color-primary;
              color: $color-background;
            }
          }
        }
      }
    }
    li.menu-item-has-children {
      &:hover a {
        color: $color-primary;
      }
      &:hover a::before {
        background: url("../assets/ico/caret-down-fill-primario.svg") no-repeat
          center center;
        background-size: cover;
      }
      > a::before {
        content: "";
        position: absolute;
        display: block;
        background: url("../assets/ico/caret-down-fill.svg") no-repeat center
          center;
        width: 9px;
        height: 9px;
        background-size: cover;
        right: 8px;
        top: 9px;
        opacity: 0.5;
      }
    }
  }
}

#offcanvasMenu {
  width: 100vw;
  border: none;
}

.offcanvas {
  &-body {
    background-color: $color-white;
    padding-top: 0.5rem;
    button {
      display: flex;
      margin-right: auto;
      margin-top: 0.5rem;
      border: none;
      background: none;
      font-size: 3rem;
      line-height: 0;

      i {
        color: $color-black;
        &:hover {
          color: $color-primary;
        }
      }
    }
  }

  &__navbar {
    display: flex;
    flex-direction: column;
    margin-top: 1.4rem;
    align-items: center;

    @media screen and (max-width: 575px) {
      margin-top: 0;
      padding-top: 0;
    }
  }

  &__menu {
    width: 100%;
    display: flex;
    justify-content: center;
    text-align: center;
  }
  &__nav {
    justify-content: flex-start;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
    font-size: 1rem;
    li.menu-item {
      margin-bottom: 0.6rem;
      @media screen and (max-width: 575px) {
        margin-bottom: 0.3rem;
      }
      & > a {
        font-size: $header-2-res;
        font-weight: $font-weight-bold;
        text-decoration: none;
        color: $color-black;
        &:hover {
          color: $color-primary;
        }
        // @media screen and (max-width: 767px) {
        //   font-size: $header-3;
        // }
      }
      ul.sub-menu {
        list-style: none;
        display: flex;
        justify-content: center;
        padding-left: 0;
        flex-wrap: wrap;
        li {
          margin: 0 5px 5px 0px;
          a {
            background-color: $color-primary;
            color: $color-white !important;
            font-size: 1.5rem;
            padding: 4px 8px;
            border-radius: $border-radius-min;
            display: inline-block;
            font-weight: $font-weight-medium;
            transition: $trans;
            &:hover {
              background-color: $color-primary !important;
              color: $color-black !important;
              transition: $trans;
            }
            @media screen and (max-width: 767px) {
              font-size: 1.25rem;
              transition: $trans;
            }
            @media screen and (max-width: 480px) {
              font-size: 1.125rem;
              transition: $trans;
            }
          }
        }
      }
    }
  }

  &__rs {
    margin: $space-01 0;
    .widget-footer__title,
    p {
      display: none;
      visibility: hidden;
    }
    display: flex;
    justify-content: center;
    margin-bottom: $space-1;
    ul {
      margin-left: 0 !important;
      margin-top: 0 !important;
    }
    .social-icon {
      font-size: 28px !important;
      color: $color-black !important;
      background-color: $color-white !important;
      padding: 3px !important;

      &:hover {
        color: $color-black !important;
        background-color: $color-primary !important;
      }
    }
  }
  &__logo {
    img,
    svg {
      display: flex;
      width: 240px;
      margin: 1rem auto 0 auto;
      transition: $trans;
      @media screen and (max-width: 575px) {
        width: 180px;
        transition: $trans;
      }
      // @media screen and (max-width: 420px) {
      //   width: 160px;
      //   transition: $trans;
      // }
    }
    // svg {
    //   path {
    //     fill: $color-white;
    //   }
    // }
  }
}
