h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $typo-primary;
  font-weight: $font-weight-bold;
}

.subtitulo {
  font-size: 1rem;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 400;
}
.titulo {
  font-size: 3rem;
  @media screen and (max-width: 1199px) {
    font-size: 2.5rem;
  }
  @media screen and (max-width: 767px) {
    font-size: 2rem;
  }
}
.titulo-max {
  font-size: 5rem !important;
  @media screen and (max-width: 1199px) {
    font-size: 4rem;
  }
  @media screen and (max-width: 767px) {
    font-size: 2rem !important;
  }
}

.bg-primaryh1,
.bg-primary.h1 {
  color: $color-background;
  font-weight: 700;
}

#breadcrumbs {
  color: #fff;
  margin-bottom: 0px;
}
#breadcrumbs a {
  color: rgba(255, 255, 255, 0.7);
}

// .header-all {
//   margin-bottom: 1.5rem;
//   margin-top: 0;
//   line-height: 1.4;
// }

// .header-1 {
//   font-size: $header-1;
//   @extend .header-all;
//   @media screen and (max-width: 810px) {
//     font-size: 2.1875rem;
//   }
// }
// .header-2 {
//   font-size: $header-2;
//   @extend .header-all;
//   @media screen and (max-width: 810px) {
//     font-size: 1.875rem;
//   }
// }
// .header-3 {
//   font-size: $header-3;
//   @extend .header-all;
//   @media screen and (max-width: 810px) {
//     font-size: 1.25rem;
//   }
// }
// .header-4 {
//   font-size: $header-4;
//   @extend .header-all;
// }
// .header-5 {
//   font-size: $header-5;
//   @extend .header-all;
// }
// .header-6 {
//   font-size: $header-6;
//   @extend .header-all;
// }

// .page-body {
//   p {
//     font-size: $font-size;
//   }
// }

// .titulos {
//   margin-bottom: 3rem;
//   @media screen and (max-width: 810px) {
//     margin-bottom: 2rem;
//   }
//   @media screen and (max-width: 480px) {
//     margin-bottom: $font-size;
//   }
//   &__pretitulo {
//     font-size: $header-4;
//     font-weight: $font-weight-bold;
//     color: $color-primary;
//     margin-bottom: $space-05;
//     @media screen and (max-width: 810px) {
//       margin-bottom: 0;
//     }
//     // @media screen and (max-width: 575px) {
//     //   font-size: $header-3;
//     // }
//     &--light {
//       color: $color-white;
//     }
//   }

//   &__titulo {
//     font-size: $header-1;
//     color: $color-black;
//     font-family: $typo-secondary;
//     line-height: 1;
//     transition: $trans;

//     &--lg {
//       font-size: $display-2;
//       @media screen and (max-width: 1280px) {
//         font-size: 3.5rem;
//       }
//     }

//     &--md {
//       font-size: $header-2;
//     }

//     &--sm {
//       font-size: $header-2;
//     }

//     &--light {
//       color: $color-white;
//     }
//     @media screen and (max-width: 991px) {
//       font-size: $header-1-res;
//       transition: $trans;
//     }
//     @media screen and (max-width: 575px) {
//       font-size: $header-2;
//       transition: $trans;
//     }

//     @media screen and (max-width: 375px) {
//       font-size: $header-2-res;
//       transition: $trans;
//     }
//   }

//   &__subtitulo {
//     font-size: $header-3-res;
//     color: $color-secondary;
//     font-family: $typo-alternative;
//     font-weight: $font-weight-medium;
//     &--sm {
//       font-size: $font-size-big;
//     }
//     &--lg {
//       font-size: $header-2;
//     }
//     &--bold {
//       font-weight: $font-weight-bold;
//     }
//     &--mb-1 {
//       margin-bottom: $space-01;
//     }
//   }

//   &__descripcion {
//     a {
//       color: $color-primary;
//       &:hover {
//         color: $color-secondary;
//       }
//     }
//     p {
//       @media screen and (max-width: 991px) {
//         margin-bottom: 0;
//       }
//     }
//   }

//   &--center {
//     text-align: center;
//   }
//   &--50 {
//     @media screen and (min-width: 992px) {
//       width: 50%;
//       margin-left: auto;
//       margin-right: auto;
//     }
//   }

//   &--primary {
//     color: $color-primary;
//   }

//   &--light {
//     color: $color-white;
//   }
//   &--mb-0 {
//     margin-bottom: 0;
//   }
//   &--mb-1 {
//     margin-bottom: $space-01;
//   }
//   &--mb-2 {
//     margin-bottom: $space-2;
//   }
//   &--mb-3 {
//     margin-bottom: $space-3;
//   }
//   &--mb-4 {
//     margin-bottom: $space-4;
//   }
//   &--mb-5 {
//     margin-bottom: $space-5;
//   }
// }
