/* Woocommerce
--------------------------------------------------------------------------- */

@media (max-width: 767px) {
  .widget__woo {
    display: none !important;
  }
}

@media (min-width: 768px) {
  .widget-resp__woo {
    display: none !important;
  }
}

.widget-resp {
  margin-bottom: 1.5rem;
}

.widget-resp__title {
  text-transform: uppercase;
  font-size: 0.8rem;
  font-weight: bold;
}
.widget-resp #product_cat {
  width: 100%;
}

.widget-resp #product_cat,
.orderby,
table.variations select {
  font-size: 16px !important;
  line-height: 18px;
  display: block;
  min-width: 240px;
  max-width: 100%;
  padding: 12px 14px 12px;
  color: #4e4e4e;
  border: 2px solid #eee !important;
  background: url("../assets/site/selectbox-arrow.png") right center no-repeat
    #fff;
  -webkit-appearance: none;
  -moz-appearance: none;
  -webkit-border-radius: 0 !important;
  -moz-border-radius: 0 !important;
  border-radius: 0 !important;
  overflow: initial;
}

/* PAGINA PRODUCTO
------------------------------------- */
.woocommerce div.product h1.product_title {
  font-size: 1.8rem;
  font-weight: bold !important;
}

.product_meta > span {
  font-size: 14px;
  display: block;
}
.product_meta > span a {
  font-weight: regular !important;
}

.summary {
  margin-bottom: 4rem !important;
}

.woocommerce-message,
.woocommerce-info {
  background-color: #f3f1db !important;
  border-top-color: $color-primary !important;
  border-radius: 4px;
}
.checkout_coupon {
  background-color: #f3f1db !important;
  border: 0px !important;
  border-radius: 4px;
}
#coupon_code {
  width: 100% !important;
  padding: 0.375rem 0.75rem;
  border-radius: 0.25rem;
  border: 1px solid #ced4da;
}

table.variations label {
  padding: 12px 14px 10px;
  text-transform: uppercase;
  background-color: $color-grey-6;
  color: $color-secondary;
  display: block;
  font-size: 14px;
  line-height: 12px;
  line-height: 20px;
  border: 2px solid #eee !important;
}
table.variations td.label {
  min-width: 10% !important;
}

.woocommerce-variation .woocommerce-variation-price {
  padding: 0px 0 20px;
}
.woocommerce-variation .woocommerce-variation-price .price .amount {
  font-size: 1.6rem !important;
}
.quantity .qty {
  padding: 0.7em 0.8em !important;
}
.summary .price .amount {
  font-size: 0.9rem !important;
}

button.single_add_to_cart_button {
  padding: 1rem 1.5rem !important;
  font-size: 1.2rem !important;
}

.woocommerce .star-rating::before {
  content: "sssss" !important;
  color: orange !important;
}

.woocommerce div.product p.price,
.woocommerce div.product span.price {
  color: $color-secondary !important;
}

/* TABS
------------------------------------- */

.tabs {
  margin-bottom: 0px !important;
}
.woocommerce-Tabs-panel {
  background-color: #fff;
  padding: 30px 40px !important;
  border: 1px solid #3c3c3c !important;
  border-top: 0px !important;
}
.woocommerce-Tabs-panel h2 {
  text-transform: uppercase;
  font-weight: bold;
  font-size: 1.2rem;
  margin-bottom: 30px;
  color: $color-fucsia;
}

.woocommerce div.product .woocommerce-tabs ul.tabs li::before,
.woocommerce div.product .woocommerce-tabs ul.tabs li::after {
  display: none;
}
.woocommerce div.product .woocommerce-tabs ul.tabs {
  padding-left: 0px !important;
  margin-left: 0px !important;
}
.woocommerce div.product .woocommerce-tabs ul.tabs::after {
  margin-bottom: 00px !important;
  border: 0 !important;
}
.woocommerce div.product .woocommerce-tabs ul.tabs li {
  border-radius: 0px !important;
  border: 0 !important;
  margin-right: 10px !important;
  text-transform: uppercase;
  font-size: 15px;
  letter-spacing: 1px;
  font-weight: regular;
}
.woocommerce div.product .woocommerce-tabs ul.tabs li.description_tab {
  border-left: 1px solid #d3ced2 !important;
}
.woocommerce div.product .woocommerce-tabs ul.tabs li {
  font-size: 13px;
  background-color: #fff !important;
}
.woocommerce div.product .woocommerce-tabs ul.tabs li.active {
  font-size: 13px;
  font-weight: bold !important;
  background-color: #d3ced2 !important;
}

.woocommerce-tabs .woocommerce-Tabs-panel {
  border: 2px solid #d3ced2 !important;
}
.woocommerce-tabs .woocommerce-Tabs-panel h2:first-child {
  color: $color-primary;
  letter-spacing: 2px;
}
.woocommerce-tabs .woocommerce-Tabs-panel p {
  color: #676767;
}
.woocommerce-tabs .woocommerce-Tabs-panel strong {
  vertical-align: top;
}

.related h2 {
  text-transform: uppercase;
  font-weight: bold;
  font-size: 1.2rem;
  margin-bottom: 30px;
  color: $color-fucsia;
}

#comments .comment-text {
  background-color: #f4f4f4;
}
#tab-description h5 {
  font-weight: bold;
  text-transform: uppercase;
  font-size: 1.5rem !important;
}

@media (max-width: 767px) {
  .woocommerce div.product .woocommerce-tabs ul.tabs li {
    letter-spacing: 0;
  }
  .woocommerce-Tabs-panel {
    padding: 30px 1rem !important;
  }

  .woocommerce-Tabs-panel h2 {
    font-size: 1.4rem;
  }
  .woocommerce-Tabs-panel h3 {
    font-size: 1.2rem;
  }
  .woocommerce-Tabs-panel h4 {
    font-size: 1rem;
  }
}

/* Pagina de productos
--------------------------------------------------------------------------- */
.shop_table,
.cart_totals {
  padding: 20px;
  border: 1px solid rgba(0, 0, 0, 0.2) !important;
  border-radius: 4px;
}
.cart_totals table.shop_table {
  border: 0px !important;
  background-color: #fafafa;
  border: 1px solid rgba(0, 0, 0, 0.2) !important;
}

.shop_table {
  background-color: #fafafa;
  border: 1px solid rgba(0, 0, 0, 0.2) !important;
}
.shop_table .coupon input,
.shop_table .coupon button {
  display: inline-block;
}

@media (max-width: 767px) {
  .woocommerce-result-count {
    display: block;
    text-align: right;
    width: 100%;
    font-size: 0.75rem;
    margin-bottom: 0.5em !important;
  }
}
/* Pagina de categorías
--------------------------------------------------------------------------- */
.term-description {
  padding: 20px 20px 10px;
  border: 3px dotted $color-primary;
  margin-bottom: 20px;
  border-radius: 10px;
}
.term-description {
  color: #787878;
}
.term-description strong {
  color: $color-secondary;
}

/* Pagina de chekcout
--------------------------------------------------------------------------- */
.checkout_coupon,
#customer_details {
  padding: 30px;
  background-color: #fff;
  margin-bottom: 30px;
  background-color: rgba(0, 0, 0, 0.02) !important;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.2) !important;
}

.woocommerce .col2-set .col-1 {
  width: 48% !important;
  max-width: 48%;
}
.woocommerce .col2-set .col-2 {
  width: 48% !important;
  max-width: 48%;
}

.woocommerce-billing-fields label {
  display: block;
  width: 100%;
}
.woocommerce-billing-fields .woocommerce-input-wrapper {
  width: 100% !important;
}
.woocommerce-billing-fields .woocommerce-input-wrapper .input-text {
  width: 100% !important;
  padding: 0.375rem 0.75rem;
  border-radius: 0.25rem;
  border: 1px solid rgba(0, 0, 0, 0.2);
}

.woocommerce-additional-fields__field-wrapper label {
  display: block;
  width: 100%;
}
.woocommerce-additional-fields__field-wrapper .woocommerce-input-wrapper {
  width: 100% !important;
}
.woocommerce-additional-fields__field-wrapper
  .woocommerce-input-wrapper
  .input-text {
  width: 100% !important;
  padding: 0.375rem 0.75rem;
  border-radius: 0.25rem;
  border: 1px solid #ced4da;
}

#payment {
  border: 1px solid rgba(0, 0, 0, 0.2);
}
.place-order .woocommerce-terms-and-conditions-wrapper {
  display: block !important;
  width: 100%;
}

button#place_order {
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 400;
  padding: 1.2em 1.9em;
}

/* VOUCHER FIELDS
--------------------------------------------------------------------------- */
.voucher-fields {
  background-color: $color-grey-6;
  padding: 20px;
  margin-bottom: 10px;
}
.voucher-fields label {
  display: block !important;
  width: 100%;
}
.voucher-fields .woocommerce-input-wrapper {
  width: 100%;
}
.voucher-fields .woocommerce-input-wrapper .input-text {
  border: 1px solid #ced4da;
  border-radius: 3px;
  padding: 0.375rem 0.75rem;
}

/* Comentarios
--------------------------------------------------------------------------- */

#commentform {
  background-color: #fff;
  padding: 20px;
}
#commentform label {
  width: 25%;
}
#comment {
  width: 100%;
  height: auto;
}
#commentform .comment-notes {
  font-size: 14px;
}

.form-submit #submit {
  padding: 12px 20px;
  background-color: $color-primary;
  color: #fff;
  display: inline-block;
  border: none;
}

ol.commentlist {
  list-style: none;
  padding-left: 0px;
}
ol.commentlist li {
}
ol.commentlist li .comment-body {
  margin-bottom: 20px;
  background-color: #fff;
  padding: 30px 30px;
  border: 1px solid #dbdbdb !important;
}
ol.commentlist li.alt {
}
ol.commentlist li.bypostauthor {
}
ol.commentlist li.byuser .comment-body {
  background-color: #feffe9;
}
ol.commentlist li.comment-author-admin {
}
ol.commentlist li.comment {
}
ol.commentlist li.comment div.comment-author {
}
ol.commentlist li.comment div.vcard {
}
ol.commentlist li.comment div.vcard cite.fn {
}
ol.commentlist li.comment div.vcard cite.fn a.url {
}
ol.commentlist li.comment div.vcard img.avatar {
}
ol.commentlist li.comment div.vcard img.avatar-32 {
}
ol.commentlist li.comment div.vcard img.photo {
}
ol.commentlist li.comment div.vcard span.says {
  font-weight: bold;
}
ol.commentlist li.comment div.commentmetadata {
  margin-bottom: 20px;
  font-size: 13px;
}
ol.commentlist li.comment div.comment-meta {
}
ol.commentlist li.comment div.comment-meta a {
}
ol.commentlist li.comment * {
}
//  (p, em, strong, blockquote, ul, ol, etc.)
ol.commentlist li.comment div.reply {
}
ol.commentlist li.comment div.reply a {
}
ol.commentlist li.comment ul.children {
  list-style: none;
  margin-top: 10px;
}
ol.commentlist li.comment ul.children li {
}
ol.commentlist li.comment ul.children li.alt {
}
ol.commentlist li.comment ul.children li.bypostauthor {
}
ol.commentlist li.comment ul.children li.byuser {
}
ol.commentlist li.comment ul.children li.comment {
}
ol.commentlist li.comment ul.children li.comment-author-admin {
}
ol.commentlist li.comment ul.children li.depth-2 {
}
ol.commentlist li.comment ul.children li.depth-3 {
}
ol.commentlist li.comment ul.children li.depth-4 {
}
ol.commentlist li.comment ul.children li.depth-5 {
}
ol.commentlist li.comment ul.children li.odd {
}
ol.commentlist li.even {
}
ol.commentlist li.odd {
}
ol.commentlist li.parent {
}
ol.commentlist li.pingback {
}
ol.commentlist li.pingback div.comment-author {
  margin-bottom: 20px;
}
ol.commentlist li.pingback div.vcard {
}
ol.commentlist li.pingback div.vcard cite.fn {
}
ol.commentlist li.pingback div.vcard cite.fn a.url {
}
ol.commentlist li.pingback div.vcard span.says {
  font-weight: bold;
}
ol.commentlist li.pingback div.commentmetadata {
  margin-bottom: 20px !important;
}
ol.commentlist li.pingback div.comment-meta {
}
ol.commentlist li.pingback div.comment-meta a {
}
ol.commentlist li.pingback * {
}
// (p, em, strong, blockquote, ul, ol, etc.)
ol.commentlist li.pingback div.reply {
}
ol.commentlist li.pingback div.reply a {
}
ol.commentlist li.pingback ul.children {
}
ol.commentlist li.pingback ul.children li {
}
ol.commentlist li.pingback ul.children li.alt {
}
ol.commentlist li.pingback ul.children li.bypostauthor {
}
ol.commentlist li.pingback ul.children li.byuser {
}
ol.commentlist li.pingback ul.children li.comment {
}
ol.commentlist li.pingback ul.children li.comment-author-admin {
}
ol.commentlist li.pingback ul.children li.depth-2 {
}
ol.commentlist li.pingback ul.children li.depth-3 {
}
ol.commentlist li.pingback ul.children li.depth-4 {
}
ol.commentlist li.pingback ul.children li.depth-5 {
}
ol.commentlist li.pingback ul.children li.odd {
}
ol.commentlist li.thread-alt {
}
ol.commentlist li.thread-even {
}
ol.commentlist li.thread-odd {
}
