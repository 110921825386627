.legal {
  padding: $space-3 0;

  @media screen and (max-width: 480px) {
    padding: $space-2 0;
  }
  &__content {
    display: grid;
    grid-template-columns: 1fr 2.5fr;
    gap: $space-2;
    @media screen and (max-width: 767px) {
      display: flex;
      flex-direction: column;
      gap: $space-2;
    }
  }

  &__sidebar {
  }
  &__info {
  }
}
