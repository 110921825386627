.wpcf7 {
  margin: 0rem 0 2rem;

  .form-group {
    margin-bottom: 1rem;
  }

  .form-label {
    font-weight: 500;
    font-size: 0.8rem;
    text-transform: uppercase;
    margin-bottom: 0.2rem !important;
  }

  .form-control {
    width: 100%;
    padding: 0.575rem 0.75rem;
    font-size: $font-size;
    background-color: transparent !important;
    border-radius: 0 !important;
    border: none;
    border-bottom: 2px solid $color-grey-3;
    color: $color-grey-3 !important;
    box-shadow: none !important;
    &::placeholder {
      color: rgba($color-grey-3, 0.8);
    }
  }

  .wpcf7-not-valid-tip {
    font-size: 12px;
    color: red;
  }
}

.wpcf7-acceptance {
  font-size: 0.6rem;
  color: $color-grey-4;
  color: rgba(0, 0, 0, 0.4);

  .wpcf7-list-item {
    // margin-left: 0;
  }
}

.wpcf7-response-output {
  margin: 2em 0.5em 1em;
  padding: 0.5em 1em;
  border-radius: 8px;
}

.wpcf7-mail-sent-ok {
  border: 2px solid #398f14;
  background-color: #398f14;
  color: $color-background;
}

.wpcf7-mail-sent-ng {
  border: 2px solid #ff0000;
  background-color: #ff0000;
  color: $color-background;
}

.wpcf7-spam-blocked {
  border: 2px solid #ffa500;
  background-color: #ffa500;
}

.wpcf7-acceptance-missig {
  border: 2px solid #ffa500 !important;
  background-color: #ffa500 !important;
}

// .wpcf7-form-control-wrap {
//   line-height: 2.9;
// }

///////////////////////////////////////////////
//  Newsletter                                /
/////////////////////////////////////////////
.newsletter {
  .wpcf7 {
    margin-bottom: 0;
  }
  .wpcf7-form .row.mt-3 .form-group {
    margin-bottom: 0 !important;
  }
}

// Google recaptcha
body:not(.page-template-page-contacto) {
  .grecaptcha-badge {
    visibility: hidden;
  }
}

///////////////////////////////////////////////
//  Complianz                                /
/////////////////////////////////////////////
.cmplz-manage-consent {
  right: 80px !important;
  background-color: #fff !important;
  color: $color-primary !important;
  //display: none;
}

.cmplz-cookiebanner {
  .cmplz-categories {
    .cmplz-category {
      background-color: transparent !important;
      border: 1px solid $color-primary !important;
      .cmplz-always-active {
        color: $color-secondary !important;
        font-weight: $font-weight-normal !important;
      }
      .cmplz-icon {
        display: flex;
        svg {
          path {
            fill: $color-white;
          }
        }
      }
    }
  }
}
