.boton {
  @include button;
}

$buttons: (
  // nom, color fondo, color texto, color texto-outline
  "primary" $color-primary $color-black $color-grey-2,
  "secondary" $color-secondary $color-white $color-grey-2,
  "accent" $color-accent $color-white $color-accent,
  "white" $color-white $color-grey-2 $color-grey-2,
  "dark" $color-grey-2 $color-white $color-grey-3,
  "whatsapp" $color-whatsapp-light $color-white $color-grey-2
);

@each $name, $c-background, $c-text, $c-text-outline in $buttons {
  .boton--#{$name} {
    background: $c-background;
    color: $c-text;
    border-color: $c-background;
    transition: $trans;
    &:hover {
      background: darken($c-background, 5%);
      border-color: darken($c-background, 5%);
      color: $c-text;
      box-shadow: $box-shadow-small;
      transform: translateY(-2px);
      transition: $trans;
    }
  }

  .boton--#{$name}-outline {
    // background: $c-background;
    color: $c-text-outline;
    border-color: $c-background;
    transition: $trans;
    &:hover {
      background: darken($c-background, 5%);
      border-color: darken($c-background, 5%);
      color: $c-text;
      box-shadow: $box-shadow-small;
      transform: translateY(-2px);
      transition: $trans;
    }
  }
}

/* Mod - Old
--------------------------------------------------------------------------- */
.btn {
  text-transform: uppercase;
  font-size: 90%;
  letter-spacing: 1px;
  font-weight: 700 !important;
  border-radius: 3px;
}
.btn-oro {
  color: #fff;
  background-color: $color-primary;
  border-color: $color-primary;
}
.btn-oro:hover {
  color: #fff;
  background-color: #c6a810;
  border-color: #c6a810;
}

a.button,
button.button,
input.button,
#review_form #submit {
  background: $color-primary !important;
  color: $color-background !important;
  border-radius: 0px !important;
}
