.scolor {
  &__item {
    margin-right: 2rem;
    margin-bottom: 2rem;
    div {
      width: 100px;
      height: 100px;
      border-radius: $border-radius-min;
      border: 1px solid $color-grey-4;
    }
  }

  small {
    font-size: 0.7rem !important;
  }
}

.sespacio {
  display: flex;
  justify-content: center;
  align-items: center;
}
