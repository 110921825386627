.cursor-pointer {
  cursor: pointer;
}

// Utilitats de colors i backgrounds
// ------------------------------------------------
$colors: (
  "primary" $color-primary,
  "primary-light" $color-primary-light,
  "primary-dark" $color-primary-dark,
  "secondary" $color-secondary,
  "secondary-light" $color-secondary-light,
  "secondary-dark" $color-secondary-dark,
  "accent" $color-accent,
  "accent-light" $color-accent-light,
  "accent-dark" $color-accent-dark,
  "white" $color-white,
  "black" $color-black,
  "dark" $color-dark,
  "grey-1" $color-grey-1,
  "grey-2" $color-grey-2,
  "grey-3" $color-grey-3,
  "grey-4" $color-grey-4,
  "grey-5" $color-grey-5,
  "grey-6" $color-grey-6
);

@each $name, $color in $colors {
  .bg-#{$name} {
    background: $color !important;
  }
}

@each $name, $color in $colors {
  .color-#{$name} {
    color: $color !important;
  }
}

// Mides funcionals per a espais
// ------------------------------------------------
$sizes: (
  "top" "t" "0" $space-0,
  "top" "t" "1" $space-1,
  "top" "t" "2" $space-2,
  "top" "t" "3" $space-3,
  "top" "t" "4" $space-4,
  "top" "t" "5" $space-5,
  "bottom" "b" "0" $space-0,
  "bottom" "b" "1" $space-1,
  "bottom" "b" "2" $space-2,
  "bottom" "b" "3" $space-3,
  "bottom" "b" "4" $space-4,
  "bottom" "b" "5" $space-5
);

// loop top i bottom
@each $direction, $d, $name, $size in $sizes {
  .pad-#{$d}-#{$name} {
    padding-#{$direction}: $size;
    @media screen and (max-width: 575px) {
      @if ($space-1) or ($space-2) {
        padding-#{$direction}: calc($size / 1.5) !important;
      } @else {
        padding-#{$direction}: $size !important;
      }
    }
  }
  .mar-#{$d}-#{$name} {
    margin-#{$direction}: $size;
    @media screen and (max-width: 575px) {
      @if ($space-1) or ($space-2) {
        margin-#{$direction}: calc($size / 1.5) !important;
      } @else {
        margin-#{$direction}: $size !important;
      }
    }
  }
}

// loop top i bottom en y
@each $direction, $d, $name, $size in $sizes {
  .pad-#{$name} {
    padding: $size 0;
    @media screen and (max-width: 575px) {
      @if ($space-1) or ($space-2) {
        padding: calc($size / 1.5) 0 !important;
      } @else {
        padding: $size 0 !important;
      }
    }
  }
  .mar-#{$name} {
    margin: $size 0;
    @media screen and (max-width: 575px) {
      @if ($space-1) or ($space-2) {
        margin: calc($size / 1.5) 0 !important;
      } @else {
        margin: $size 0 !important;
      }
    }
  }
}

.img-rounded {
  border-radius: $border-radius;
}
.img-rounded-shadow {
  border-radius: $border-radius;
  box-shadow: $box-shadow;
}

.lista-tags {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 0.5rem;
  &--va {
    @media screen and (max-width: 575px) {
      justify-content: center;
      .lista-tags__item {
        background-color: $color-secondary;
        color: $color-white;
        justify-content: center;
        a {
          color: $color-white;
        }
      }
    }
  }

  &__item {
    margin-right: $font-size-small;
    margin-bottom: 0.5rem;
    background-color: $color-white;
    padding: 2px 6px;
    border-radius: 3px;
    font-size: $font-size-small;
    a {
      color: $color-grey-2;
    }
    &:nth-last-child(1) {
      margin-right: 0;
    }
  }
}

.container--max {
  max-width: 1400px;
  margin: 0 auto;
  padding-left: 20px;
  padding-right: 20px;
  @media screen and (min-width: 768px) and (max-width: 1440px) {
    padding-left: 40px;
    padding-right: 40px;
  }
}

.container--min {
  max-width: 870px;
}

.text-center {
  text-align: center;
}

.oculto {
  visibility: hidden;
  opacity: 0;
  transition: $trans;
}
.visible {
  visibility: visible;
  opacity: 1;
  transition: $trans;
}

.d-block {
  display: block;
}

.d-none {
  display: none;
  visibility: hidden;
  transition: $trans;
}

/* Espacios y colores - Old
--------------------------------------------------------------------------- */
.padding-min {
  padding: 20px 0;
}
.padding {
  padding: 60px 0;
}
.padding-max {
  padding: 100px 0;
}
.padding-max-extra {
  padding: 180px 0;
}
.padding-header {
  padding: 60px 0;
}

@media (max-width: 767px) {
  .padding-min {
    padding: 20px 0;
  }
  .padding {
    padding: 30px 0;
  }
  .padding-max {
    padding: 100px 0;
  }
  .padding-max-extra {
    padding: 180px 0;
  }
}

@media (max-width: 767px) {
  .padding-header {
    padding: 15px 0;
    text-align: left;
  }
  .padding-header h1,
  .padding-header .h1 {
    font-size: 1.5rem;
    text-transform: initial;
    letter-spacing: 0px;
    margin-bottom: 0;
  }
  #breadcrumbs {
    font-size: 0.75rem;
  }
}

.mleft--100 {
  margin-left: -100px;
  @media screen and (max-width: 767px) {
    margin-left: 0px;
  }
}

.blanco {
  color: $color-background;
}
.primary {
  color: $color-primary;
}
.secondary {
  color: $color-secondary;
}
.p-dark,
.p-dark p {
  color: rgba(255, 255, 255, 0.7);
}

.bg-primary {
  background-color: $color-primary;
}
.bg-secondary {
  background-color: $color-secondary;
  color: #fff;
}
.bg-grey {
  background-color: $color-grey-6;
}

.w-30 {
  width: 30% !important;
}

.mbm-multiply {
  mix-blend-mode: multiply;
}
