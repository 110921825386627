/* Claim
--------------------------------------------------------------------------- */
.claim {
  &__container {
    background: url("../assets/site/bg-top.jpg") bottom center;
    background-size: cover;
    height: 70vh;
    position: relative;
    margin-bottom: 15px;
    @media screen and (max-width: 767px) {
      height: 50vh;
    }
    .overlay {
      position: absolute;
      top: 0;
      bottom: 0;
      width: 100%;
      z-index: 0;
      background-image: url("../assets/site/bg-striped.png");
    }
  }
  &__container-elements {
    z-index: 1;
    text-align: center;
  }
  &__container-elements h1,
  &__container-elements a {
    color: $color-white;
    margin-bottom: 5px !important;
    display: inline-block;
    &.titulo-max {
      @media screen and (max-width: 767px) {
        font-size: 3rem !important;
      }
    }
  }

  &__container-elements a {
    font-size: $font-size;
    background-color: $color-primary;
    padding: 2px 10px;
    border-radius: 3px;
    text-decoration: none;
    &:hover {
      background-color: $color-secondary;
    }
  }
}

.recon {
  @media screen and (max-width: 767px) {
    margin-bottom: 40px;
  }
  a {
    color: $color-secondary;
    &:hover {
      color: $color-primary;
      text-decoration: underline;
    }
  }
  &__logo {
    height: 70px;
    @media screen and (max-width: 767px) {
      height: auto;
    }
    img {
      width: auto;
      @media screen and (max-width: 767px) {
        width: 30% !important;
        margin-bottom: 10px !important;
      }
    }
  }
  &__caption {
    color: $color-primary !important;
    font-size: 1.2rem;
  }
}

ul.galeria {
  column-count: 7;
  column-gap: 0.1rem;
  margin-top: 20px;
  li {
    flex-direction: column;
  }
}

video.flex-video {
  width: 100%;
}

.historia {
  font-size: 1.2rem;
  line-height: 2.1rem;
  color: #3c3c3c;
  font-weight: 300;
  @media screen and (max-width: 1199px) {
    column-count: 3;
    font-size: $font-size;
  }
  @media screen and (max-width: 767px) {
    column-count: 1;
    font-size: 1rem;
  }
}

.historia-002 {
  column-count: 2;
  column-rule-style: solid;
  column-gap: 50px;
  column-rule-width: 2px;
  font-size: 1.2rem;
  line-height: 2.1rem;
  color: #3c3c3c;
  font-weight: 300;
}

.columnas__3 {
  column-count: 3;
  column-rule-style: dotted;
  column-gap: 50px;
  column-rule-width: 0px;
  font-size: 1rem;
  line-height: 1.5rem;
  color: #3c3c3c;
}

.galeria {
  column-count: 4;
  column-gap: 0.1rem;
  a {
    flex-direction: column;
    margin-bottom: 0.1rem;
    display: inline-block;
  }
}

.maps iframe {
  pointer-events: none;
}

ul.contactos {
  li {
    padding: 10px 0;
    color: rgba(0, 0, 0, 0.5);
    span {
      padding-right: 15px;
      border-right: 3px solid $color-primary;
      color: $color-secondary;
      margin-right: 12px;
      width: 30px;
      display: block;
      float: left;
    }
  }
}
