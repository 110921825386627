/* Footer / Widgets - Old
--------------------------------------------------------------------------- */
.widget {
  display: block;
}
.widget ul.menu,
.widget > ul {
  list-style: none;
  padding-left: 0px;
}

.widget ul > li {
  padding: 10px 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}
.widget ul > li:last-child {
  border-bottom: 0px solid rgba(0, 0, 0, 0.2);
}
.widget ul > li.current-cat a {
  color: $color-secondary !important;
  font-weight: bold;
}

#footer .widget ul > li {
  padding: 10px 0;
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
}
#footer .widget ul > li:last-child {
  border-bottom: 0px solid rgba(255, 255, 255, 0.5);
}

.widget__title {
  margin-bottom: 20px;
  text-transform: uppercase;
  font-size: 15px;
  font-weight: bold;
}
.widget__title::after {
  content: "";
  width: 40px;
  height: 5px;
  background-color: rgba(128, 128, 128, 0.2);
  display: block;
  margin-top: 10px;
}

#footer .widget__title {
  color: #fff;
}
#footer .widget__title::after {
  background-color: #fff;
}

.widget .tagcloud a {
  font-size: 15px !important;
  border-radius: 2px;
  padding: 2px 8px;
  background-color: #c6a810;
  margin: 0 3px 6px 0;
  display: inline-block;
  color: $color-background !important;
}

.widget {
  margin-bottom: 50px;
}
#footer .widget__title {
  color: #fff !important;
}
#footer a {
  color: #9e9a7b;
  font-weight: 400;
}

.widget_recent_entries > ul > li a {
  display: block;
}
.widget_recent_entries > ul > li span.post-date {
  font-size: 12px;
}

aside.blog .widget_archive ul li {
  width: 40%;
  display: inline-block;
  margin-right: 5%;
  font-size: 1rem;
  padding: 8px 0;
}
aside.blog .widget_categories ul li {
  display: inline-block;
  border: none;
  margin-bottom: 0px;
}
aside.blog .widget_categories ul li a {
  background-color: $color-secondary;
  color: $color-background !important;
  padding: 5px 10px;
  border: 0px;
  border-radius: 3px;
}
aside.blog .widget_categories ul li a:hover {
  background-color: $color-primary;
  color: $color-background !important;
}

#footer .zoom-social-icons-list li {
  border-bottom: 0px !important;
}

.product_list_widget li a .product-title {
  font-weight: normal;
  font-size: 0.9rem;
  margin-bottom: 10px;
}

.woocommerce ul.product_list_widget li img {
  width: 45px !important;
}
