html {
  scroll-behavior: smooth;
}

//   /* Font Smoothing */
//   -webkit-font-smoothing: antialiased;
//   -moz-font-smoothing: antialiased;
//   -moz-osx-font-smoothing: grayscale;
//   -ms-font-smoothing: antialiased;
//   -o-font-smoothing: antialiased;
//   font-smoothing: antialiased;
// }

body {
  background-color: $color-background;
  color: $color-text-body;
  font-family: $typo-primary;
  font-size: $font-size;
  font-weight: $font-weight-normal;
}

strong {
  font-family: $typo-secondary;
  font-weight: $font-weight-bold;
}

a {
  color: $color-primary;
  transition: $trans;
}
a:hover,
a:active,
a:focus {
  font-family: $typo-primary;
  color: $color-secondary;
  text-decoration: none;
  transition: $trans;
}
